<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="基本情報" icon="">
				<b-field label="名前"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'name') }"
					:message="errors.name">
					<b-input type="text" v-model="name" />
				</b-field>

				<b-field label="品番"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'code') }"
					:message="errors.code">
					<b-input type="text" v-model="code" />
				</b-field>

				<b-field label="ブランド" custom-class="require" horizontal>
					<b-select v-model="brandId">
						<option v-for="(row, index) in brands" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>

				<b-field label="画像" horizontal>
					<div>
						<img class="preview" :src="imageBase" v-show="!image" :style="{ 'max-width': previewW + 'px' }">
						<image-picker class="mb-10" :previewUrl="image" :isLoading="isImageLoading" :previewW="previewW" @file-change="onImageChange" @delete="onImageDelete"></image-picker>
					</div>
				</b-field>

				<!--
				<b-field label="説明" horizontal>
					<b-input type="textarea" v-model="detail" />
				</b-field>
				-->

				<b-field label="価格(税抜)"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'price') }"
					:message="errors.price">
					<b-input type="text" v-model="price" />
				</b-field>

				<b-field label="課税区分" custom-class="require" horizontal>
					<b-select v-model="taxClassId">
						<option v-for="(row, index) in taxClasses" :key="index" :value="row.id">
							{{ row.name }}({{ row.rate }}%)
						</option>
					</b-select>
				</b-field>

				<b-field label="納期"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'leadTime') }"
					:message="errors.leadTime">
					<div class="leadtime-wrapper">
						<span class="mr-10">注文「確定」日から</span>
						<b-input type="text" v-model="leadTime" />
						<span class="ml-10">日後にお届け</span>
					</div>
				</b-field>

				<b-field label="入数"
					horizontal
					message="※エフピコ商品の場合は数字のみで入力">
					<b-input type="text" v-model="quantity" />
				</b-field>

				<b-field label="単位" horizontal>
					<b-input type="text" v-model="unit" />
				</b-field>

				<b-field label="エフピコ注文番号"
					horizontal
					custom-class=""
					:type="{ 'is-danger': $utils.hasError(errors, 'fpItemCode') }"
					:message="$utils.hasError(errors, 'fpItemCode') ? errors.fpItemCode : '※エフピコ自動発注の場合は必須'">
					<b-input type="text" v-model="fpItemCode" placeholder="半角数字8桁まで"/>
				</b-field>

				<b-field label="エフピコ価格"
					horizontal
					custom-class=""
					:type="{ 'is-danger': $utils.hasError(errors, 'fpPrice') }"
					:message="$utils.hasError(errors, 'fpPrice') ? errors.fpPrice : '※エフピコ自動発注の場合は必須'">
					<b-input type="text" v-model="fpPrice"/>
				</b-field>

				<b-field label="カテゴリー" horizontal>
					<ul class="category">
						<li v-for="row in categories" :key="row.id">
							<b-checkbox v-model="menuCategories"
								:native-value="row.id">
								{{ row.name }}
							</b-checkbox>
						</li>
					</ul>
				</b-field>

				<b-field label="関連メニュー" horizontal class="mt-30">
					<b-button class="is-success" @click="isRecommendModalActive = true">追加</b-button>
				</b-field>

				<b-field label="" horizontal>
					<ul class="recommend">
						<li v-for="(row, index) in recommends" :key="row.id">
							<div class="horizontal-center">
								<b-button type="is-danger" icon-right="delete" @click="onRecommendMenuDelete(index)"/>
								<div class="recommend-name">{{ row.name }}</div>
							</div>
						</li>
					</ul>
				</b-field>

				<b-field label="ステータス"
					horizontal
					class="mt-30"
					custom-class="require"
					>
					<radio-picker :options="menuStatuses" v-model="menuStatudId"></radio-picker>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.go(-1)">戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">{{ !id ? '登録' : '更新' }}</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>
		</section>

		<b-modal :active.sync="isRecommendModalActive" has-modal-card>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">メニューを選択してください</p>
				</header>

				<section class="modal-card-body">
					<b-select v-model="recommendBrandId" @input="onRecommendModalBrandChange">
						<option value="">ブランド選択</option>
						<option v-for="row in brands" :key="row.id" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>

					<b-select class="mt-20" v-model="recommendMenuId" @input="onRecommendModalMenuChange">
						<option value="">メニュー選択</option>
						<option v-for="row in recommendMenus" :key="row.id" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>

					<img class="mt-20" :src="recommendMenuImage" v-show="recommendMenuImage" style="max-width: 150px">

				</section>

				<footer class="modal-card-foot">
					<b-button @click="closeRecommendModal">キャンセル</b-button>
					<b-button type="is-success" @click="onRecommendModalAddButtonClick" :disabled="recommendMenuId === ''">追加</b-button>
				</footer>
			</div>
		</b-modal>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';
.recommend {
	li:not(:last-child) {
		margin-bottom: 10px;
	}
	.button {
		width: 20px;
		margin-right: 15px;
	}
}
.category {
	li:not(:first-child) {
		margin-top: 10px;
	}
}
.leadtime-wrapper {
	@include flex;

	::v-deep input {
		width: 70px;
	}
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'
import ImagePicker from '@/components/ImagePicker'

export default {
	name: 'MenuEdit',
	components: {
		RadioPicker,
		CardComponent,
		TitleBar,
		ImagePicker,
	},
	data () {
		return {
			id: '',
			name: '',
			code: '',
			detail: '',
			image: '',
			imageBase: '',
			price: null,
			leadTime: null,
			fpItemCode: '',
			fpPrice: '',
			unit: '',
			quantity: '',
			brandId: '',
			taxClassId: null,
			menuStatudId: null,
			menuCategories: [],
			errors: {
				name: '',
				code: '',
				price: '',
				leadTime: '',
				fpItemCode: '',
				fpPrice: '',
			},
			isLoading: false,
			isImageLoading: false,
			previewW: 200,
			brands: [],
			menuStatuses: null,
			categories: [{'': ''}],
			isRecommendModalActive: false,
			recommendBrandId: '',
			recommendMenuId: '',
			recommendMenuImage: '',
			recommendMenus: [],
			recommendAllMenus: [],
			recommends: [],
			taxClasses: [],
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		onRecommendMenuDelete: function(index) {
			this.recommends.splice(index, 1);
		},
		onRecommendModalBrandChange: function() {
			this.recommendMenuImage = '';
			this.recommendMenuId = '';
			this.recommendMenus = [];

			if (this.recommendBrandId) this.recommendMenus = this.recommendAllMenus[this.recommendBrandId];
		},
		onRecommendModalMenuChange: function() {
			this.recommendMenuImage = '';
			if (this.recommendMenuId) this.recommendMenuImage = this.recommendMenus.filter(function(value) { return value.id === this.recommendMenuId }, this)[0].image;
		},
		onRecommendModalAddButtonClick: function() {
			let tmp = this.recommendMenus.filter(function(value) { return value.id === this.recommendMenuId }, this)[0];
			this.recommends.push({
				id: tmp.id,
				name: tmp.name
			});

			this.closeRecommendModal();
		},
		closeRecommendModal: function() {
			this.recommendBrandId = '';
			this.recommendMenuId = '';
			this.recommendMenuImage = '';
			this.recommendMenus = [];
			this.isRecommendModalActive = false;
		},
		onImageDelete: function() {
			this.image = '';
		},
		onImageChange: function(file) {
			this.uploadImage(file);
		},
		uploadImage: async function(file, key) {
			this.isImageLoading = true;

			let data = {
				"image": file,
			};
			let ep = 'menus/image';

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.image = res.data.url;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isImageLoading = false;
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.menuId ? '/' + this.$route.params.menuId : '';
			let ep = 'menus/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.name = res.data.name;
				this.code = res.data.code;
				this.image = res.data.image;
				this.imageBase = res.data.image_base;
				this.detail = res.data.detail;
				this.price = res.data.price;
				this.leadTime = res.data.lead_time;
				this.fpItemCode = res.data.fp_item_code;
				this.fpPrice = res.data.fp_price;
				this.quantity = res.data.quantity;
				this.unit = res.data.unit;
				this.brandId = res.data.brand_id;
				this.taxClassId = res.data.tax_class_id;
				this.menuCategories = res.data.menu_categories;
				this.menuStatudId = res.data.menu_status_id;
				this.brands = res.data.brands;
				this.menuStatuses = res.data.menu_statuses;
				this.categories = res.data.categories;
				this.recommendAllMenus = res.data.recommend_all_menus;
				this.recommends = res.data.recommends;
				this.taxClasses = res.data.tax_classes;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"name": this.name,
				"code": this.code,
				"image": this.image,
				"detail": this.detail,
				"price": this.price,
				"lead_time": this.leadTime,
				"fp_item_code": this.fpItemCode,
				"fp_price": this.fpPrice,
				"quantity": this.quantity,
				"unit": this.unit,
				"brand_id": this.brandId,
				"menu_status_id": this.menuStatudId,
				"tax_class_id": this.taxClassId,
			};

			let mc = '';
			if (this.menuCategories.length > 0) mc = this.menuCategories;
			data.menu_tags = mc;

			let rcm = '';
			if (this.recommends.length > 0) {
				rcm = [];
				for (let i = 0; i < this.recommends.length; i++) {
					rcm.push(this.recommends[i].id);
				}
			}
			data.recommends = rcm;

			this.$utils.log(data);

			try {
				let ep = 'menus';

				if (!this.id) {
					await this.$api.post(ep, data, true);
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
				this.$store.commit('basic', {
					key: 'menuBrandId',
					value: this.brandId,
				});
				this.$router.push({ name: 'menu' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
	}
}
</script>
