<template>
	<div class="searchbar">
		<div class="wrap">
			<slot/>

			<div v-if="isSearchButtonShow" class="button-area">
				<b-button type="is-info" @click="onSearchClick">検索</b-button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.searchbar {
	padding: 0 .75rem;
	border-bottom: 1px solid rgba(24, 28, 33, 0.06);

	.wrap {
		display: flex;
		align-items: center;

		.button-area {
			min-width: 150px;
			text-align: right;
			margin-left: auto;
		}
	}
}
</style>

<script>

export default {
	name: 'SearchBar',
	props: {
		isSearchButtonShow: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	methods: {
		onSearchClick: function() {
			this.$emit('search');
		},
	},
}
</script>
