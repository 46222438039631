<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧（ドラッグ＆ドロップで表示順序を変更できます）" icon="format-list-bulleted">
				<b-button slot="button" type="is-success is-small" @click="$router.push({ name: 'menu-new' })">新規作成</b-button>

				<search-bar :isSearchButtonShow="false" slot="toolbar">
					<div class="search-wrap" slot>
						<div class="row">
							<div class="search-type">
								<div class="search-title">表示中のブランド</div>
								<b-select v-model="brandId" @input="onBrandChange">
									<option v-for="row in brands" :key="row.id" :value="row.id">
										{{ row.name }}
									</option>
								</b-select>
							</div>
						</div>
					</div>
				</search-bar>

				<b-table
					:loading="isLoading"
					:paginated="false"
					:striped="true"
					:hoverable="true"
					:data="list"
					draggable
					@dragstart="dragstart"
					@drop="drop"
					@dragover="dragover"
					@dragleave="dragleave"
					>

					<b-table-column label="メニュー名" field="name" v-slot="props">
						{{ props.row.name }}
					</b-table-column>

					<b-table-column label="品番" field="code" v-slot="props">
						{{ props.row.code }}
					</b-table-column>

					<b-table-column label="価格" field="price" v-slot="props">
						{{ props.row.price.toLocaleString() }}
					</b-table-column>

					<b-table-column label="ステータス" field="status" v-slot="props">
						{{ props.row.status }}
					</b-table-column>

					<b-table-column label=" " v-slot="props">
						<div class="buttons is-right">
							<button class="button is-small is-warning" type="button" @click="$router.push({ name: 'menu-edit', params: { menuId: props.row.id }})">編集</button>
							<button class="button is-small is-danger" type="button" @click="onDeleteClick(props.row.id)">削除</button>
						</div>
					</b-table-column>

					<section class="section" slot="empty">
						<table-empty :loading="isLoading"></table-empty>
					</section>
				</b-table>
			</card-component>
		</section>
	</div>

</template>

<style lang="scss" scoped>
.search-wrap {
	flex-wrap: wrap;

	.search-type {
		margin-left: 5px;
	}

	.search-type,
	.row {
		display: flex;
		align-items: center;
		margin: 5px 0;
	}

	.search-title {
		font-weight: bold;
		margin-right: 10px;
	}
}
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import SearchBar from '@/components/SearchBar'
import TableEmpty from '@/components/TableEmpty'

export default {
	name: 'Menu',
	components: {
		TitleBar,
		CardComponent,
		SearchBar,
		TableEmpty,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			brands:[],
			brandId: '',
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
	},
	mounted () {
		this.init();
	},
	methods: {
		dragstart (payload) {
			this.draggingRow = payload.row
			this.draggingRowIndex = payload.index
			payload.event.dataTransfer.effectAllowed = 'copy'
		},
		dragover(payload) {
			payload.event.dataTransfer.dropEffect = 'copy'
			payload.event.target.closest('tr').classList.add('is-selected')
			payload.event.preventDefault()
		},
		dragleave(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected')
			payload.event.preventDefault()
		},
		drop(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected')
			const droppedOnRowIndex = payload.index
			//this.$buefy.toast.open(`Moved ${this.draggingRow.name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)

			if (this.draggingRowIndex === droppedOnRowIndex) return;

			this.reorder(this.draggingRowIndex, droppedOnRowIndex);
		},
		reorder: async function(from, to) {
			let tmpList = this.list;
			let target = tmpList.splice(from, 1);
			Array.prototype.splice.apply(tmpList, [to, 0].concat(target));

			this.isLoading = true;
			let data = {};
			let list = [];
			for (let i in tmpList) {
				list.push({
					"id": tmpList[i].id,
					"ordering": Number(i) + 1,
				});
			}
			data.list = list;
			data.brand_id = this.brandId;

			this.$utils.log(data);

			try {
				let ep = 'menus/reorder';
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('表示順を更新しました');
				this.list = [];
				this.list = tmpList;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onBrandChange: function() {
			this.getData();
		},
		init: function() {
			// 新規作成、編集後は登録したメニューのブランドを表示
			if (this.$store.state.menuBrandId) {
				this.brandId = this.$store.state.menuBrandId;
				this.$store.commit('basic', {
					key: 'menuBrandId',
					value: null,
				});
			}

			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'menus';
			if (typeof this.brandId !== 'undefined' && this.brandId !== '') ep += '?brand_id=' + this.brandId;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
				this.brands = res.data.brands;
				this.brandId = res.data.brand_id;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onDeleteClick: async function(id) {
			this.$utils.log(id);
			if (!window.confirm(this.$consts.MSG_CONFIRM_DELETE)) return;

			this.isLoading = true;
			let ep = 'menus/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.delete(ep, null, true);
				this.$utils.log(res);
				
				this.list.some(function(value, index) {
					if (value.id === id) this.list.splice(index, 1);
				}, this);

				this.$utils.toastSuccess('削除しました');
				this.getData();
			} catch (error) {
				this.$utils.log(error);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
	},
}
</script>
